<template>
  <div class="vx-card p-6">
    <base-ag-grid-header
      :rows-selected-count="rowsSelectedCountWithAllSelection"
      :filters-count="filtersAppliedCount"
      :filters-match.sync="filtersMatch"
      @reset-selection="setSelectedRowsNone()"
      @reset-filters="resetFilters()">
      <template v-slot:header-left>
        <items-per-page
          :count="count"
          :currentPage="currentPage"
          :paginationPageSize="paginationPageSize"
          @changed-page-size="paginationSetPageSize">
        </items-per-page>

        <list-action-drop-down
          v-if="isAnyRowsSelected"
          :options="defaultMultipleActionOptions"
          class="ml-3"
          @export="exportData()"
          @delete="confirmDeleteRecords()">
        </list-action-drop-down>
      </template>

      <template v-slot:header-right>
        <vs-button
          v-avoid-key-tab-event
          color="primary"
          type="border"
          :size="headerButtonsSizeByResolution"
          icon-pack="feather"
          icon="icon-plus"
          class="mr-3"
          @click="newItem">
          {{ $t("$General.AddNew") }}
        </vs-button>

        <vs-button
          radius
          color="primary"
          type="border"
          icon-pack="feather"
          icon="icon-refresh-cw"
          @click="resetDataSourceAndSelection()">
        </vs-button>
      </template>
    </base-ag-grid-header>

    <selection-alert-info
      v-model="selectionPageAlert"
      class="mb-5"
      :entity="entity"
      :count="count"
      :rows-selected-count="rowsSelectedCount"
      @select-all="setSelectedRowsAll()"></selection-alert-info>

    <export-alert-info
      :entity="$tc(`$Entities.${entity}`, 2)"
      :exporting.sync="exportingList"
      :exported.sync="exportedList"
      :fileUrl="urlFileExported"
      :send-email="exportSendEmail"/>

    <ag-grid-vue
      :key="agGridKey"
      ref="agGridTable"
      :domLayout="domLayout"
      :style="gridStyle"
      :components="components"
      :frameworkComponents="frameworkComponents"
      :gridOptions="gridOptions"
      class="ag-theme-material w-100 ag-grid-table"
      :columnDefs="columnDefs"
      :defaultColDef="defaultColDef"
      :column-types="columnTypes"
      :getRowNodeId="getRowNodeId"
      :autoParamsRefresh="true"
      rowSelection="multiple"
      :animateRows="true"
      :suppressRowClickSelection="true"
      rowModelType="infinite"
      :pagination="hasPagination"
      :paginationPageSize="paginationPageSize"
      :suppressPaginationPanel="suppressPaginationPanel"
      :overlayLoadingTemplate="overlayLoadingTemplate"
      :maxConcurrentDatasourceRequests="maxConcurrentDatasourceRequests"
      :cacheBlockSize="cacheBlockSize"
      :maxBlocksInCache="maxBlocksInCache"
      @selection-changed="onSelectionChanged"
      @grid-ready="onGridReady"
      @model-updated="onModelUpdate"
      @grid-size-changed="onGridSizeChanged"
      @first-data-rendered="onFirstDataRendered"
      @row-clicked="onRowClicked"
      @row-double-clicked="onRowDoubleClicked"
      @filter-changed="onFilterChanged"
      @pagination-changed="onPaginationChanged">
    </ag-grid-vue>

    <vs-popup
      :title="titleModalWithCreateOrEdit"
      :active.sync="activeModalCreateOrEdit">
      <transition name="zoom-fade">
        <caller-id-list-create-or-edit
          v-if="showCreateOrEditComponent"
          :entity="entity"
          :operation="operation"
          :model-payload="recordSelected"
          :actions="actionsOnCreateOrEdit"
          :caller-id-type="createOrEditType"
          :show-only-caller-id-type="$enums.Sender.Caller.Type.DEDICATED_NUMBER"
          @changed-type="(type) => createOrEditType = type"
          @action="onActionFromCreateOrEditForSender"
          @model-was-changed="(val) => this.createOrEditModelWasChanged=val"
          @saved="savedItemOnAgGridTable"
          @close="activeModalCreateOrEdit = false"
          @update-modal-title="(val) => this.titleModalFromCreateOrEdit = val"/>
      </transition>
    </vs-popup>

    <vs-popup
      :title="exportModalTitle"
      :active.sync="activeModalToExport">
      <transition name="zoom-fade">
        <export-json-to-excel
          v-if="showExportComponent"
          :columns="getColumnsToExport"
          :data="rowsSelected"
          @close="activeModalToExport=false"
          @export="onExport">
        </export-json-to-excel>
      </transition>
    </vs-popup>

    <vs-popup
      :title="$t('$SenderIdsModules.VerificationModalTitle', {
        entity: this.$tc(`$Entities.${this.entity}`),
        name: this.recordToVerify ? this.recordToVerify.name : '',
        value: this.recordToVerify ? this.recordToVerify.phoneInternational: ''
      })"
      :active.sync="activeModalVerify">
      <transition name="zoom-fade">
        <sender-id-verification
          v-if="activeModalVerify"
          :model-payload="recordToVerify"
          :message="$t('$CallerIdModule.VerifyPhoneMsg')"
          :entity="entity"
          :send-code="sendVerificationCode"
          @close="activeModalVerify=false"
          @verified="afterVerifySenderId()">
        </sender-id-verification>
      </transition>
    </vs-popup>

    <dedicated-number-payment-checkout-modal
      v-if="recordToVerify"
      v-model="activePaymentDedicatedNumberModal"
      :caller-id="recordToVerify"
      @payment="resetDataSourceAndSelection()"/>
  </div>
</template>

<script>
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss';
import { mapActions, mapState } from 'vuex';

// Mixins
import commonListSenderId from '@/views/modules/sender-ids/_common/mixins/common-list-sender-id.mixin';

// Cell Renderer
import CallerIdCellRendererStatus from '@/views/modules/sender-ids/caller-id/CallerIdCellRendererStatus.vue';
import CellRendererActions from '@/views/modules/sender-ids/_components/SenderIdCellRendererActions.vue';
import CallerIdListCellRendererPhone from '@/views/modules/sender-ids/caller-id/CallerIdListCellRendererPhone.vue';

// Custom Components
import CallerIdListCreateOrEdit from '@/views/modules/sender-ids/caller-id/CallerIdListCreateOrEdit.vue';
import SenderIdVerification from '@/views/modules/sender-ids/_components/SenderIdVerification.vue';
import BaseAgGridHeader from '@/views/modules/_components/BaseAgGridHeader.vue';
import ItemsPerPage from '@/views/modules/_components/ItemsPerPage.vue';
import ExportJsonToExcel from '@/views/modules/_components/ExportJsonToExcel.vue';
import ListActionDropDown from '@/views/modules/_components/ListActionDropDown.vue';
import AgGridSelectionHeader from '@/views/modules/_components/AgGridSelectionHeader';
import ExportAlertInfo from '@/views/modules/_components/ExportAlertInfo.vue';
import DedicatedNumberPaymentCheckoutModal
from '@/views/modules/sender-ids/caller-id/_components/DedicatedNumberPaymentCheckoutModal.vue';
import SelectionAlertInfo from '@/views/modules/SelectionAlertInfo.vue';

// Others
import { registerSocketEvent, unregisterSocketEvent } from '@/socket';

/**
 * Component to list and manage Caller IDs
 *
 * @module views/modules/sender-ids/caller-id/CallerIdList
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 * @vue-data {string} [entity='...'] - Name of the entity associated to list
 * @vue-data {Function} exportCollectionFunction - function to export the collection
 * @vue-data {Function | null} deleteRecordFunction - function to delete one record
 * @vue-data {Function | null} [deleteRecordsFunction=null] - function to delete records
 * @vue-data {number} [modalsDelay=0] - create or edit modals delay
 * @vue-data {string} [titleModalFromCreateOrEdit=''] - title modal from create or edit
 * @vue-data {string} [createOrEditType=''] - create or edit type
 * @vue-data {Array.<Object>} columnDefs - Columns definition of ag grid to list
 * @vue-data {Object} components - Components to use to extend ag grid
 * @vue-data {Object} frameworkComponents - Framework components to extend ag grid
 * @vue-data {string} titleModalWithCreateOrEdit - title modal for create or edit
 * @vue-event {void} fetchAgGridData - fetch data to ag grid table
 */
export default {
  name: 'CallerIdList',
  components: {
    BaseAgGridHeader,
    ExportJsonToExcel,
    ItemsPerPage,
    ListActionDropDown,
    SenderIdVerification,
    ExportAlertInfo,
    DedicatedNumberPaymentCheckoutModal,
    CallerIdListCreateOrEdit,
    SelectionAlertInfo,
    // eslint-disable-next-line vue/no-unused-components
    CellRendererActions,
    // eslint-disable-next-line vue/no-unused-components
    CallerIdCellRendererStatus,
    // eslint-disable-next-line vue/no-unused-components
    CallerIdListCellRendererPhone,
  },
  mixins: [commonListSenderId],
  data() {
    return {
      entity: this.$enums.Entity.CALLER_ID,
      exportCollectionFunction: this.exportCallerIds,
      deleteRecordFunction: this.removeCallerId,
      deleteRecordsFunction: this.removeCallerIds,
      modalsDelay: 0,
      titleModalFromCreateOrEdit: '',
      createOrEditType: '',
      columnDefs: [
        {
          colId: 'name',
          headerName: this.$t('$General.Name'),
          field: 'name',
          filter: true,
          minWidth: 100,
          maxWidth: 1200,
          checkboxSelection: true,
          headerComponentParams: { checkboxSelection: true },
        },
        {
          colId: 'phoneInternational',
          headerName: this.$tc('$General.Phone'),
          field: 'phoneInternational',
          filter: true,
          minWidth: 100,
          maxWidth: 1200,
          cellRendererFramework: 'CallerIdListCellRendererPhone',
        },
        {
          colId: 'status',
          headerName: this.$t('$General.Status'),
          headerClass: 'center-herder-ag-grid',
          field: 'status',
          filter: false,
          minWidth: 100,
          maxWidth: 466,
          cellRendererFramework: 'CallerIdCellRendererStatus',
        },
      ],
      components: {
        CellRendererActions,
        CallerIdCellRendererStatus,
        CallerIdListCellRendererPhone,
      },
      frameworkComponents: {
        agColumnHeader: AgGridSelectionHeader,
      },

      socketEventsListenerId: 'caller-id-list',
    };
  },
  computed: {
    ...mapState({
      lastCreated: (state) => state.callerId.lastCreated, // review is this is necessary or no
    }),
    titleModalWithCreateOrEdit() {
      return this.titleModalFromCreateOrEdit || this.titleModal;
    },
  },
  watch: {
    showCreateOrEditComponent(val) {
      if (val) {
        if (this.operation !== this.$enums.Operation.CREATE) {
          this.createOrEditType = this.recordSelected.type;
        } else {
          this.createOrEditType = '';
        }
      } else {
        this.createOrEditType = '';
      }
    },
  },
  mounted() {
    registerSocketEvent(this.$enums.SocketEvents.CALLER_ID_UPDATED, (callerId) => {
      this.refreshRowNodeDataById(callerId.id, callerId);
    },
    this.socketEventsListenerId,
    true);
  },
  beforeDestroy() {
    unregisterSocketEvent(this.$enums.SocketEvents.CALLER_ID_UPDATED, this.socketEventsListenerId);
  },
  methods: {
    ...mapActions({
      fetchAllCallerIds: 'callerId/fetchAllCallerIds',
      exportCallerIds: 'callerId/exportFile',
      removeCallerId: 'callerId/removeCallerId',
      removeCallerIds: 'callerId/removeCallerIds',
    }),
    async fetchAgGridData(params) {
      return this.fetchAllCallerIds(params);
    },
  },
};
</script>
