<template>
  <div class="flex items-center">
    <vx-tooltip
      :text="$t(`$Enums.Sender.Caller.Type.${type}`)"
      class="cursor-pointer flex items-center">
      <feather-icon
        :icon="icon"
        svgClasses="h-5 w-5 hover:text-primary"/>
    </vx-tooltip>

    <span class="ml-3">
      {{ phoneNumber }}
    </span>
  </div>
</template>

<script>

/**
 * Cell renderer to show Sender ID status
 *
 * @module views/modules/sender-ids/caller-id/CallerIdListCellRendererPhone
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 * @vue-computed  {string} phoneNumber - phone number to show
 * @vue-computed  {string} type - type of caller number
 * @vue-computed  {string} icon - icon to show
 */
export default {
  name: 'CallerIdListCellRendererPhone',
  computed: {
    phoneNumber() {
      return this.params && this.params.value
        ? this.params.value
        : '';
    },
    type() {
      return this.params && this.params.data
        ? this.params.data.type
        : '';
    },
    icon() {
      return this.type === this.$enums.Sender.Caller.Type.DEDICATED_NUMBER
        ? 'PhoneIcon' : 'SmartphoneIcon';
    },
  },
};
</script>

<style scoped>

</style>
