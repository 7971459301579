<template>
  <div>
    <div
      v-if="selectedTypeIsEmpty"
      class="vx-row">
      <div
        v-for="(option, index) in callerNumberTypesOptions"
        :key="index"
        class="vx-col w-1/2">
        <app-button-card
          :icon="option.icon"
          :text="option.text"
          icon-background
          @selected="onSelectedAction(option.type)"/>
      </div>
    </div>

    <caller-id-list-create-or-edit-from-phone-number
      v-if="selectedTypeIsPhoneNumber"
      :entity="entity"
      :operation="operation"
      :model-payload="modelPayload"
      :actions="actions"
      @action="(val) => $emit('action', val)"
      @model-was-changed="(val) => $emit('model-was-changed', val)"
      @saved="(val) => $emit('saved', val)"
      @close="() => $emit('close')">
      <template
        v-if="!showOnlyCallerIdType && isCreation"
        v-slot:top>
        <go-back-arrow
          divider
          @back="selectedType = ''"/>
      </template>
    </caller-id-list-create-or-edit-from-phone-number>

    <caller-id-list-create-or-edit-from-dedicated-number
      v-if="selectedTypeIsDedicatedNumber"
      :operation="$enums.Operation.CREATE"
      :entity="entity"
      :dedicated-numbers="availableNumbers"
      @saved="(val) => $emit('saved', val)"
      @update-modal-title="(title) => $emit('update-modal-title', title)">
      <template v-slot:before-table>
        <go-back-arrow
          v-if="!showOnlyCallerIdType && isCreation"
          divider
          @back="selectedType = ''"/>
      </template>
    </caller-id-list-create-or-edit-from-dedicated-number>
  </div>
</template>

<script>
import enums from '@/enums';

// Components
import AppButtonCard from '@/views/modules/_components/AppButtonCard.vue';
import CallerIdListCreateOrEditFromPhoneNumber from '@/views/modules/sender-ids/caller-id/CallerIdListCreateOrEditFromPhoneNumber.vue';
import CallerIdListCreateOrEditFromDedicatedNumber from '@/views/modules/sender-ids/caller-id/CallerIdListCreateOrEditFromDedicatedNumber.vue';
import GoBackArrow from '@/views/modules/_components/GoBackArrow.vue';

// Mixins
import commonCreateOrEditSenderId
from '@/views/modules/sender-ids/_common/mixins/common-create-or-edit-sender-id';
import callerIdService from '@/api/modules/caller-id.service';

/**
 * Component to create or edit a caller id for type
 *
 * @module views/modules/sender-ids/caller-id/CallerIdListCreateOrEdit
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 * @vue-prop {string} [callerIdType=''] - type of caller id to create prop
 * @vue-data {string} [selectedType=''] - type of caller id to create
 * @vue-data {string[]} [callerNumberTypesOptions=[...]] - caller number type options
 * @vue-data {string[]} availableNumbers - dedicated numbers info
 * @vue-data {boolean} selectedTypeIsEmpty - true if selected type is empty, otherwise false
 * @vue-data {boolean} selectedTypeIsPhoneNumber -
 * true if selected type is phone number, otherwise false
 * @vue-data {boolean} selectedTypeIsDedicatedNumber -
 * true if selected type is dedicated number, otherwise false
 * @vue-event {void} onSelectedAction - fetch data to ag grid table
 * @vue-event {void} getAvailableDedicatedNumbers - fetch the available dedicated numbers
 */
export default {
  name: 'CallerIdListCreateOrEdit',
  i18n: {
    messages: {
      en: {
        $CallerNumbersTypes: {
          PhoneNumber: 'User your phone number',
          DedicatedNumber: 'Get a dedicated number',
        },
      },
    },
  },
  components: {
    AppButtonCard,
    GoBackArrow,
    CallerIdListCreateOrEditFromPhoneNumber,
    CallerIdListCreateOrEditFromDedicatedNumber,
  },
  mixins: [commonCreateOrEditSenderId],
  props: {
    showOnlyCallerIdType: {
      type: String,
      required: false,
      default: '',
      validator(type) {
        return [
          '',
          enums.Sender.Caller.Type.PHONE_NUMBER,
          enums.Sender.Caller.Type.DEDICATED_NUMBER,
        ].includes(type);
      },
    },
    callerIdType: {
      type: String,
      required: false,
      default: '',
      validator(type) {
        return [
          '',
          enums.Sender.Caller.Type.PHONE_NUMBER,
          enums.Sender.Caller.Type.DEDICATED_NUMBER,
        ].includes(type);
      },
    },
  },
  data() {
    return {
      selectedType: this.callerIdType,
      callerNumberTypesOptions: [
        {
          text: this.$t('$CallerNumbersTypes.PhoneNumber'),
          icon: 'SmartphoneIcon',
          type: this.$enums.Sender.Caller.Type.PHONE_NUMBER,
        },
        {
          text: this.$t('$CallerNumbersTypes.DedicatedNumber'),
          icon: 'PhoneIcon',
          type: this.$enums.Sender.Caller.Type.DEDICATED_NUMBER,
        },
      ],
      availableNumbers: [],
    };
  },
  computed: {
    selectedTypeIsEmpty() {
      return this.selectedType === '' && this.showOnlyCallerIdType === '';
    },
    selectedTypeIsPhoneNumber() {
      return (this.selectedType === this.$enums.Sender.Caller.Type.PHONE_NUMBER
        || this.showOnlyCallerIdType === this.$enums.Sender.Caller.Type.PHONE_NUMBER)
        || this.isViewOrEdit;
    },
    selectedTypeIsDedicatedNumber() {
      return (this.selectedType === this.$enums.Sender.Caller.Type.DEDICATED_NUMBER
        || this.showOnlyCallerIdType === this.$enums.Sender.Caller.Type.DEDICATED_NUMBER)
        && this.isCreation;
    },
  },
  watch: {
    selectedTypeIsDedicatedNumber(val) {
      if (!val) {
        this.$emit('update-modal-title', '');
      }
    },
  },
  created() {
    this.getAvailableDedicatedNumbers();

    this.$emit('update-modal-title', '');
  },
  methods: {
    onSelectedAction(type) {
      this.selectedType = type;
      this.$emit('changed-type', type);
    },
    async getAvailableDedicatedNumbers() {
      const resp = await callerIdService.getAvailableDedicatedNumbers();
      this.availableNumbers = resp.data;
    },
  },
};
</script>

<style scoped>

</style>
