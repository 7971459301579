<template>
  <common-cell-renderer-actions
    :actions="filteredActionsByType"
    @pay="payDedicatedNumber"
    @check="verifySenderId()"
    @view="viewRecord()"
    @clone="cloneRecord()"
    @edit="editRecord()"
    @delete="confirmDeleteRecord()"
    @subscription="redirectDedicatedNumbers()">
  </common-cell-renderer-actions>
</template>

<script>
// Mixins
import commonSingleCellRendererActions from '@/views/modules/_mixins/commonSingleCellRendererActions';
import commonCellRenderers from '@/views/modules/_mixins/commonCellRenderers';

// Components
import CommonCellRendererActions from '@/views/modules/_components/cell-renderer/CommonCellRendererActions.vue';

/**
 * Cell renderer to Sender ID actions
 *
 * @module views/modules/sender-ids/SenderIdCellRendererActions
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 * @vue-computed  {string} status - status of caller
 * @vue-computed  {string} type - type of caller
 * @vue-computed  {boolean} isDedicatedNumber - indicate if caller is dedicated number
 * @vue-computed  {boolean} isPhoneNumberVerified - indicate if caller is verified phone number
 * @vue-computed  {boolean} isDedicatedNumberMissingPayment - indicate if caller is missing payment dedicated number
 * @vue-computed  {boolean} isDedicatedNumberExpiredPayment - indicate if caller is expired payment dedicated number
 * @vue-computed  {boolean} isDedicatedNumberMissingOrExpiredPayment - indicate if caller is missing or expired payment dedicated number
 * @vue-computed  {Object[]} filteredActionsByType - actions filtered by type of sender
 * @vue-event  {void} verifySenderId - called to show verification modal
 * @vue-event  {void} payDedicatedNumber - called to show payment modal
 * @vue-event  {Object[]} filteredActionsByPhoneNumberStatus - actions filtered by status of phone number
 * @vue-event  {Object[]} filteredActionsByDedicatedNumberStatus - actions filtered by status of dedicated number
 */
export default {
  components: {
    CommonCellRendererActions,
  },
  mixins: [commonCellRenderers, commonSingleCellRendererActions],
  name: 'SenderIdCellRendererActions',
  computed: {
    status() {
      return this.paramsData
        && this.paramsData.status
        ? this.paramsData.status
        : '';
    },
    type() {
      return this.paramsData
        ? this.paramsData.type : '';
    },
    isDedicatedNumber() {
      return this.type === this.$enums.Sender.Caller.Type.DEDICATED_NUMBER;
    },
    isPhoneNumberVerified() {
      return this.status === this.$enums.Sender.Status.VERIFIED;
    },
    isDedicatedNumberMissingPayment() {
      return this.status === this.$enums.Sender.Caller.DedicatedNumberStatus.MISSING_PAYMENT;
    },
    isDedicatedNumberExpiredPayment() {
      return this.status === this.$enums.Sender.Caller.DedicatedNumberStatus.EXPIRED_PAYMENT;
    },
    isDedicatedNumberCanceled() {
      return this.status === this.$enums.Sender.Caller.DedicatedNumberStatus.CANCELED;
    },
    isDedicatedNumberMissingOrExpiredPayment() {
      return this.isDedicatedNumberMissingPayment || this.isDedicatedNumberExpiredPayment;
    },
    isDedicatedNumberExpiredPaymentOrCanceled() {
      return this.isDedicatedNumberExpiredPayment || this.isDedicatedNumberCanceled;
    },
    isDedicatedNumberMissingExpiredOrCanceled() {
      return this.isDedicatedNumberMissingOrExpiredPayment || this.isDedicatedNumberCanceled;
    },
    filteredActionsByType() {
      if (this.isDedicatedNumber) {
        // eslint-disable-next-line no-underscore-dangle
        const _actions = this.filteredActionsFromParent.filter(
          (action) => action.name !== 'check'
            && action.name !== this.$enums.Operation.DELETE,
        );

        return this.filteredActionsByDedicatedNumberStatus(_actions);
      }

      // eslint-disable-next-line no-underscore-dangle
      const _actions = this.filteredActionsFromParent.filter(
        (action) => action.name !== 'pay'
        && action.name !== 'subscription',
      );
      return this.filteredActionsByPhoneNumberStatus(_actions);
    },
  },
  methods: {
    verifySenderId() {
      this.params.context.componentParent.recordToVerify = this.paramsData;
      this.params.context.componentParent.activeModalVerify = true;
    },
    payDedicatedNumber() {
      this.params.context.componentParent.recordToVerify = this.paramsData;
      this.params.context.componentParent.activePaymentDedicatedNumberModal = true;
    },
    filteredActionsByPhoneNumberStatus(actions) {
      if (this.isPhoneNumberVerified) {
        return actions.filter(
          (action) => action.name !== 'check',
        );
      }

      return actions;
    },
    filteredActionsByDedicatedNumberStatus(actions) {
      if (!this.isDedicatedNumberMissingExpiredOrCanceled) {
        return actions.filter(
          (action) => action.name !== 'pay',
        );
      }

      if (this.isDedicatedNumberExpiredPaymentOrCanceled) {
        return actions.map(
          (action) => (action.name === 'pay'
            ? {
              ...action,
              text: '$General.Resubscribe',
            }
            : action),
        );
      }

      return actions;
    },
    redirectDedicatedNumbers() {
      this.$router.push(`/purchases/my-purchases/my-dedicated-numbers?id=${this.paramsData.id}`);
    },
  },
};
</script>
