<template>
    <vs-chip
      v-if="paramsValue"
      class="ag-grid-cell-chip"
      :color="chipColorByType">
      <feather-icon
        :icon="chipIconByType"
        svgClasses="h-5 w-5 mr-1" />
      <span>
        <a
          href="#"
          :class="[
            `text-dark`,
            !this.chipIsClickable ? 'pointer-events-none' : ''
          ]"
          @click.prevent="onStatusClicked()">
          {{ statusText }}
        </a>
      </span>
    </vs-chip>
</template>

<script>
import commonCellRenderers from '@/views/modules/_mixins/commonCellRenderers';

/**
 * Cell renderer to show Caller ID status
 *
 * @module views/modules/sender-ids/caller-id/CallerIdCellRendererStatus
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 * @vue-computed  {string} chipColorByType - chip color depending on caller type
 * @vue-computed  {string} chipColorPhoneNumber - chip color depending on verification status
 * @vue-computed  {string} chipColorDedicatedNumber - chip color depending on payment status
 * @vue-computed  {string} chipIconByType - chip icon depending on caller type
 * @vue-computed  {string} chipIconPhoneNumber - chip icon depending on verification status
 * @vue-computed  {string} chipIconDedicatedNumber - chip icon depending on payment status
 * @vue-computed  {boolean} isVerified - indicate if the status is verified
 * @vue-computed  {string} type - type of sender
 * @vue-computed  {boolean} isDedicatedNumber - indicate if sender is a dedicated number
 * @vue-computed  {boolean} isMissingPayment -
 * indicate if sender is a dedicated number with missing payment status
 * @vue-computed  {boolean} isExpiredPayment -
 * indicate if sender is a dedicated number with expired payment status
 * @vue-computed  {boolean} isMissingOrExpiredPayment -
 * indicate if sender is a dedicated number with missing or expired payment status
 * @vue-computed  {string} statusText - text to show in status cell
 * @vue-computed  {string} chipIsClickable - indicate if chip is clickable
 * @vue-event  {void} onStatusClicked - called to show action modal
 */
export default {
  name: 'CallerIdCellRendererStatus',
  mixins: [commonCellRenderers],
  computed: {
    chipColorByType() {
      return this.isDedicatedNumber
        ? this.chipColorDedicatedNumber
        : this.chipColorPhoneNumber;
    },
    chipColorPhoneNumber() {
      return this.paramsValue === this.$enums.Sender.Status.VERIFIED ? 'success' : 'danger';
    },
    chipColorDedicatedNumber() {
      switch (this.paramsValue) {
        case this.$enums.Sender.Caller.DedicatedNumberStatus.PROCESSING_PAYMENT:
          return 'primary';

        case this.$enums.Sender.Caller.DedicatedNumberStatus.ACTIVE:
          return 'success';

        case this.$enums.Sender.Caller.DedicatedNumberStatus.MISSING_PAYMENT:
          return 'warning';

        case this.$enums.Sender.Caller.DedicatedNumberStatus.EXPIRED_PAYMENT:
          return 'danger';

        default:
          return '';
      }
    },
    chipIconByType() {
      return this.isDedicatedNumber
        ? this.chipIconDedicatedNumber
        : this.chipIconPhoneNumber;
    },
    chipIconPhoneNumber() {
      return this.isVerified ? 'CheckCircleIcon' : 'XCircleIcon';
    },
    chipIconDedicatedNumber() {
      switch (this.paramsValue) {
        case this.$enums.Sender.Caller.DedicatedNumberStatus.PROCESSING_PAYMENT:
          return 'ClockIcon';

        case this.$enums.Sender.Caller.DedicatedNumberStatus.ACTIVE:
          return 'CheckSquareIcon';

        case this.$enums.Sender.Caller.DedicatedNumberStatus.MISSING_PAYMENT:
          return 'XSquareIcon';

        case this.$enums.Sender.Caller.DedicatedNumberStatus.EXPIRED_PAYMENT:
        case this.$enums.Sender.Caller.DedicatedNumberStatus.CANCELED:
          return 'XIcon';

        default:
          return '';
      }
    },
    isVerified() {
      return this.paramsValue === this.$enums.Sender.Status.VERIFIED;
    },
    type() {
      return this.paramsData
        ? this.paramsData.type : '';
    },
    isDedicatedNumber() {
      return this.type === this.$enums.Sender.Caller.Type.DEDICATED_NUMBER;
    },
    isMissingPayment() {
      return this.paramsValue === this.$enums.Sender.Caller.DedicatedNumberStatus.MISSING_PAYMENT;
    },
    isExpiredPayment() {
      return this.paramsValue === this.$enums.Sender.Caller.DedicatedNumberStatus.EXPIRED_PAYMENT;
    },
    isCanceled() {
      return this.paramsValue === this.$enums.Sender.Caller.DedicatedNumberStatus.CANCELED;
    },
    isMissingOrExpiredPayment() {
      return this.isMissingPayment || this.isExpiredPayment;
    },
    isMissingExpiredOrCanceled() {
      return this.isMissingOrExpiredPayment || this.isCanceled;
    },
    statusText() {
      return this.isDedicatedNumber
        ? this.$t(`$Enums.Sender.Caller.DedicatedNumberStatus.${this.paramsValue}`)
        : this.$t(`$Enums.Sender.Status.${this.paramsValue}`);
    },
    chipIsClickable() {
      return (this.isDedicatedNumber && this.isMissingExpiredOrCanceled)
        || (!this.isDedicatedNumber && !this.isVerified);
    },
  },
  methods: {
    onStatusClicked() {
      if (this.isDedicatedNumber) {
        if (this.isMissingExpiredOrCanceled) {
          this.params.context.componentParent.recordToVerify = this.paramsData;
          this.params.context.componentParent.activePaymentDedicatedNumberModal = true;
        }
      } else if (!this.isVerified) {
        this.params.context.componentParent.recordToVerify = this.paramsData;
        this.params.context.componentParent.activeModalVerify = true;
      }
    },
  },
};
</script>
