import commonListCreateOrEditWithAgGrid from '@/views/modules/_mixins/commonListCreateOrEditWithAgGrid';
import senderIdVerification from '@/views/modules/_mixins/senderIdVerification.mixin';

/**
 * mixins for sender list
 *
 * @module src/views/modules/sender-ids/mixins/commonListSenderId
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 * @vue-data {boolean} cloneDisabled - indicate if clone operation if disabled
 * @vue-data {boolean} [activePaymentDedicatedNumberModal=false] -
 * indicate if show the dedicated number payment modal
 * @vue-computed {Object[]} [additionalActionsOnList=[]] - additional actions on list
 * @vue-event {void} onActionFromCreateOrEditForSender - called on action from create or edit
 * @vue-event {void} onPaySenderFromCreateOrEdit - called on pay action from create or edit
 * @vue-event {void} onCheckSenderFromCreateOrEdit - called on check form create or edit
 * @vue-event {void} afterVerifySenderId - called after sender verification
 */
export default {
  mixins: [
    commonListCreateOrEditWithAgGrid,
    senderIdVerification,
  ],
  data() {
    return {
      cloneDisabled: true,
      activePaymentDedicatedNumberModal: false,
    };
  },
  computed: {
    additionalActionsOnList() {
      return [
        {
          name: 'check',
          color: 'success',
          text: '$General.Verify',
          position: 'top',
          icon: 'icon-check-circle',
          iconVue: 'CheckCircleIcon',
          activeCreateOrEdit: this.operation === this.$enums.Operation.VIEW
            || this.operation === this.$enums.Operation.EDIT,
          active: true,
        },
        {
          name: 'pay',
          color: 'primary',
          text: '$General.Pay',
          position: 'top',
          icon: 'icon-credit-card',
          iconVue: 'CreditCardIcon',
          activeCreateOrEdit: this.operation === this.$enums.Operation.VIEW
            || this.operation === this.$enums.Operation.EDIT,
          active: true,
        },
        {
          name: 'subscription',
          color: 'primary',
          text: '$General.Subscription',
          position: 'top',
          icon: 'icon-settings',
          iconVue: 'SettingsIcon',
          activeCreateOrEdit: this.operation === this.$enums.Operation.VIEW,
          active: true,
        },
      ];
    },
  },
  methods: {
    onActionFromCreateOrEditForSender(action) {
      switch (action) {
        case 'pay':
          this.onPaySenderFromCreateOrEdit(this.recordSelected);
          break;
        case 'check':
          this.onCheckSenderFromCreateOrEdit(this.recordSelected);
          break;
        case 'subscription':
          this.onSubscriptionFromCreateOrEdit(this.recordSelected);
          break;

        default:
          this.onActionFromCreateOrEdit(action);
      }
    },
    async onPaySenderFromCreateOrEdit(payload) {
      this.dontConfirmCloseCreateOrEdit = true;
      this.activeModalCreateOrEdit = false;

      const confirmed = await this.confirmCloseCreateOrEditModal();

      if (confirmed) {
        this.recordToVerify = payload;
        this.activePaymentDedicatedNumberModal = true;
      } else {
        this.activeModalCreateOrEdit = true;
      }

      this.dontConfirmCloseCreateOrEdit = false;
    },
    async onCheckSenderFromCreateOrEdit(payload) {
      this.dontConfirmCloseCreateOrEdit = true;
      this.activeModalCreateOrEdit = false;

      const confirmed = await this.confirmCloseCreateOrEditModal();

      if (confirmed) {
        this.showCreateOrEditComponent = false;
        this.recordToVerify = payload;
        this.activeModalVerify = true;
      } else {
        this.activeModalCreateOrEdit = true;
      }

      this.dontConfirmCloseCreateOrEdit = false;
    },
    async onSubscriptionFromCreateOrEdit(payload) {
      this.dontConfirmCloseCreateOrEdit = true;
      this.activeModalCreateOrEdit = false;

      const confirmed = await this.confirmCloseCreateOrEditModal();

      if (confirmed) {
        this.showCreateOrEditComponent = false;
        setTimeout(() => {
          this.$router.push(`/purchases/my-purchases/my-dedicated-numbers?id=${payload.id}`);
        }, 100);
      } else {
        this.activeModalCreateOrEdit = true;
      }

      this.dontConfirmCloseCreateOrEdit = false;
    },
    afterVerifySenderId() {
      this.activeModalVerify = false;
      this.resetDataSourceAndSelection();
    },
  },
};
