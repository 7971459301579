/**
 * Common for all cell renderers
 *
 * @module views/modules/mixins/commonCellRenderers
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 * @vue-computed  {string} paramsValue - params value
 * @vue-computed  {string} paramsData - params data
 */
export default {
  computed: {
    paramsValue() {
      return this.params && this.params.value
        ? this.params.value : null;
    },
    paramsData() {
      return this.params && this.params.data
        ? this.params.data : null;
    },
  },
};
