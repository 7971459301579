<template>
  <vx-card
    class="overflow-hidden card cursor-pointer"
    @mousedown="$emit('selected')">
    <div slot="no-body">
      <div
        class="p-6"
        :class="{
        'flex justify-around flex-row-reverse items-center': iconPosIsRight,
        'flex justify-around items-center': iconPosIsLeft,
        'text-center': iconPosIsCenter,
        }">
        <feather-icon
          :icon="icon"
          class="p-3 inline-flex rounded-full"
          :class="[`text-${color}`, {'mb-4': iconPosIsCenter}]"
          :svgClasses="`w-${iconSize} h-${iconSize}`"
          :style="iconBackgroundStyle"></feather-icon>
        <div class="truncate">
          <a
            href="#"
            class="mb-1 font-bold link-plain"
            @click="$emit('selected')">
            {{ text }}
          </a>
        </div>
      </div>
    </div>

    <template
      v-if="this.$slots.footer"
      v-slot:footer>
      <slot name="footer"></slot>
    </template>
  </vx-card>
</template>

<script>
const ICON_POSITION = {
  LEFT: 'left',
  CENTER: 'center',
  RIGHT: 'right',
};

Object.freeze(ICON_POSITION);

/**
 * Clickable card button
 *
 * @module views/modules/components/AppButtonCard
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 * @vue-prop {string} icon - Icon to show
 * @vue-prop {Number} [iconSize=8] - Icon size
 * @vue-prop {'left' | 'center' | 'right'} [iconPosition='center'] - Icon size
 * @vue-prop {boolean} [iconBackground=false] - indicate if apply background to icon
 * @vue-prop {string} text - text to show
 * @vue-prop {string} [color='primary'] - color to use
 * @vue-computed {boolean} iconPosIsLeft - indicate if icon position is left
 * @vue-computed {boolean} iconPosIsCenter - indicate if icon position is center
 * @vue-computed {boolean} iconPosIsRight - indicate if icon position is right
 * @vue-computed {string} iconBackgroundStyle - background style to icon
 */
export default {
  name: 'AppButtonCard',
  props: {
    icon: {
      type: String,
      required: true,
    },
    iconSize: {
      type: Number,
      required: false,
      default: 8,
    },
    iconPosition: {
      type: String,
      required: false,
      default: 'center',
      validator(pos) {
        return Object.values(ICON_POSITION).includes(pos);
      },
    },
    iconBackground: {
      type: Boolean,
      required: false,
      default: false,
    },
    text: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      default: 'primary',
    },
  },
  computed: {
    iconPosIsLeft() {
      return this.iconPosition === ICON_POSITION.LEFT;
    },
    iconPosIsCenter() {
      return this.iconPosition === ICON_POSITION.CENTER;
    },
    iconPosIsRight() {
      return this.iconPosition === ICON_POSITION.RIGHT;
    },
    iconBackgroundStyle() {
      return this.iconBackground
        ? { background: `rgba(var(--vs-${this.color}),.15)` }
        : '';
    },
  },
};
</script>

<style lang="scss" scoped>
.card:hover {
  box-shadow: 0 0 15px rgba(33,33,33,.2);
}
</style>
